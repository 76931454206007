import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import Select from 'components/Select';
import { useVehicle } from '../shared/context';
import { fetchShippingCompanies } from 'utils/fetches'

export default function OwnerData() {
  const vehicle = useVehicle();
  const { vehicle_id } = useParams();

  useEffect(() => {
    if (!vehicle_id) {
      vehicle.setData({
        owner: null,
        anttOwner: null,
        beneficiary: null
      });
    }
  }, []);

  return (
    <Row className="form">
      <Col xs={12}>
        <Select.Async
          label='Proprietário do documento *'
          onSearch={value => fetchShippingCompanies(value)}
          value={vehicle.data.owner}
          horizontal
          onChange={value => { vehicle.setData({ owner: value }) }}
          getOptionLabel={option => `${option.socialName || option.social_name} 
          - ${option.cpfCnpj || option.cgccpf}`}
          getOptionValue={option => option.id}
          error={vehicle.errors?.owner}
        />

      </Col>
      <Col xs={12}>
        <Select.Async
          label='Beneficiário do veiculo *'
          onSearch={value => fetchShippingCompanies(value)}
          value={vehicle.data.beneficiary}
          horizontal
          onChange={value => { vehicle.setData({ beneficiary: value }) }}
          getOptionLabel={option => `${option.socialName || option.social_name} 
            - ${option.cpfCnpj || option.cgccpf}`}
          getOptionValue={option => option.id}
          error={vehicle.errors?.beneficiary}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label={'Proprietário RNTRC *'}
          onSearch={value => fetchShippingCompanies(value)}
          onChange={value => vehicle.setData({ anttOwner: value })}
          value={vehicle.data.anttOwner}
          getOptionLabel={option => `${option.socialName || option.social_name} 
            - ${option.cpfCnpj || option.cgccpf}`}
          getOptionValue={option => option.id}
          error={vehicle.errors?.anttOwner}
        />
      </Col>
    </Row>
  );
}
